import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// @ts-ignore
import { Eclipse } from "react-loading-io";

import { setLogout } from "@app/store/user.store";
import { useCartQuantity, useCartSyncronized } from '@app/store/selectors';

import useIsUserLoggedIn from "@app/utils/user";
import { useCurrentLanguage, useSwitchLanguage } from "@app/utils/language";
import UserService from "@app/services/user.service";

import { img } from "@app/style/utils";
import { showGlobalModal } from "@app/utils/modals";
/*
import flagBG from "@app/scss/img/foodyto/bg.svg";
import flagEN from "@app/scss/img/foodyto/en.svg";
*/
function LanguageSelector() {
    const currentLanguage = useCurrentLanguage();
    const switchLanguage = useSwitchLanguage();

    return (
        <>
            {/*
                window.appLanguages.map((code: string, key: any) => code === currentLanguage ?
                    <section key={key} onClick={(e) => switchLanguage(code)}>
                        <img
                            src={code === 'bg' ? flagBG : flagEN}
                            alt={code === 'bg' ? 'Български' : 'English'}
                            className="img-fluid"
                            style={{width: '30px', borderRadius: '4px', cursor: 'pointer'}}
                        />
                        <span className="text">
                            {code}
                        </span>
                    </section> : null)
    */}
            <span className="dropdown-menu-option" onClick={() => switchLanguage(currentLanguage === 'bg' ? 'en' : 'bg')}>
                <img src={img('icons/globe.svg')} className="svg-sign-in" alt={''} />{currentLanguage === 'bg' ? 'EN' : 'BG'}
            </span>
        </>
    );
}

function LoggedInLinksMenu() {
    const { t } = useTranslation();

    return (
        <>
            {/*<li>
                <Link to=""
                    className="dropdown-toggle"
                    id="alertsDropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowNotifications(!showNotifications);
                    }}>
                    <img src={img('icons/alarm.svg')} className="svg-alarm mr-2 mr-lg-0" alt={''} />
                    <span className="d-lg-none">{t('Известия')}</span>
                </Link>
                <div className={`dropdown-menu overflow-hidden dropdown-notifications${showNotifications ? ' show' : ''}`} aria-labelledby="alertsDropdown">
                    <h6 className="dropdown-header text-center">{t('Нови')}:</h6>
                    <NotificationsComponent/>
                    <Link className="dropdown-item small text-center" to="#">{t('VIEW_ALL')}</Link>
                </div>
            </li>*/}
            <li><Link to="/home">{t('Аз Foody-то')}</Link></li>
            <li><Link to="/offers">{t('Хапни')}</Link></li>
            <li><Link to="/vendors">{t('Места')}</Link></li>
            <li><Link to="/blog">{t('Блог')}</Link></li>
            <div className="top-menu-auth-options">
                <li><Link to="/home"><span>{t('Дашборд')}</span></Link></li>
                <li><Link to="/past-orders"><span>{t('Поръчки')}</span></Link></li>
                <li><Link to="/profile"><span>{t('Профил')}</span></Link></li>
                <li><Link to="/notifications"><span>{t('Известия')}</span></Link></li>
            </div>
        </>
    );
}

function LoggedOutLinksMenu() {
    const { t } = useTranslation();

    return (
        <>
            <li><Link to="/offers">{t('Хапни')}</Link></li>
            <li><Link to="/partners">{t('Стани партньор')}</Link></li>
            <li><Link to="/blog">{t('Блог')}</Link></li>
            <li><Link to="/about">{t('За нас')}</Link></li>
        </>
    );
}

function LinksMenu() {
    const isLoggedIn = useIsUserLoggedIn();

    return (
        <ul>
            {isLoggedIn
                ? <LoggedInLinksMenu />
                : <LoggedOutLinksMenu />}
        </ul>
    );
}

function LoggedInIconsMenu() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const logout = (e: any) => {
        e?.preventDefault();

        UserService
            .logout()
            .then(res => {
                dispatch(setLogout());
                localStorage.removeItem('jwt_token');

                window.location.pathname = '/';
            })
            .catch(e => console.error(e));
    };

    return (
        <>
            <li>
                <span className="dropdown-menu-option" onClick={logout}>
                    <img src={img('icons/sign-out.svg')} className="svg-sign-out mr-2" alt={''} />
                    {t('Изход')}
                </span>
            </li>
            <li className={`lang mr-2 ${window.loginEnabled ? 'lang-hide-mobile' : ''}`}>
                <LanguageSelector />
            </li>
        </>
    );
}

function LoggedOutIconsMenu() {
    const { t } = useTranslation();

    return (
        <>
            {window.loginEnabled ?
                <li>
                    <span className="dropdown-menu-option" onClick={() => showGlobalModal('login')}>
                        <img src={img('icons/sign-in.svg')} className="svg-sign-in" alt={''} />{t('SIGN_IN')}
                    </span>
                </li> : null}
            <li className={`lang ${window.loginEnabled ? 'lang-hide-mobile' : ''}`}>
                <LanguageSelector />
            </li>
        </>
    );
}

function IconsMenu() {
    const { t } = useTranslation();
    const syncronized = useCartSyncronized();
    const cartCount = useCartQuantity();
    const isLoggedIn = useIsUserLoggedIn();

    return (
        <ul id="right-menu" className={window.loginEnabled ? 'ml-lg-2': ''}>
            {isLoggedIn
                ? <LoggedInIconsMenu />
                : <LoggedOutIconsMenu />}
            <li className="cart">
                {syncronized &&
                    <Link
                        to="/checkout"
                        title={t('Количка')}>
                        <>
                            <img className="cart-icon" src={img('icons/cart-alt.svg')} alt={''} />
                            {cartCount ?
                                <span className="count">
                                    {cartCount}
                                </span> : null}
                        </>
                    </Link>}
                {!syncronized &&
                    <Eclipse size={22} width={6} color="#333" />}
            </li>
        </ul>
    );
}

export default function Header({ sticky }: { sticky: boolean }) {
    const location = useLocation();

    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(false);
    }, [location]);

    return (
        <header className={sticky ? `header no-promo-bar clearfix element_to_stick` : `header_in no-promo-bar dark clearfix`}>
            {/*<PromoBar headerMenuOpened={opened} /> */}
            <div className="container">
                <div id="logo">
                    <Link to="/">
                        <img src={img('logo/foody-underlined.png')} width="110" alt="" className="standard" />
                        <img src={img('logo/foody-dark-underlined.png')} width="130" alt="" className="white" />
                    </Link>
                </div>

                <IconsMenu />

                <a onClick={() => setOpened(!opened)} className="open_close">
                    <i className="icon_menu"></i><span>Menu</span>
                </a>

                {window.loginEnabled
                    ? <div className="left-menu"><ul><li><LanguageSelector /></li></ul></div>
                    : null}

                <nav className={`main-menu ${opened ? 'show' : ''}`}>
                    <div id="header_menu">
                        <a onClick={() => setOpened(!opened)} className="open_close">
                            <i className="icon_close"></i><span>Menu</span>
                        </a>
                        <Link to="/"><img src={img('logo/foody-dark-underlined.png')} width="140" alt="" /></Link>
                    </div>
                    <LinksMenu />
                </nav>
            </div>
        </header>
    );
}
