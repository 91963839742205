import { IGeoCoordinates } from './../interfaces/geo.interface';
import { Slice, createSlice } from '@reduxjs/toolkit';

export interface SearchState {
    place: google.maps.places.PlaceResult,
    near: IGeoCoordinates,
}

const initialState: SearchState = {
    place: null,
    near: null,
};

const slice: Slice<any, any, any> = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchState: (state: SearchState, action?: any): any => {
            const { payload } = action as { payload: SearchState };

            Object.keys(payload).forEach(key => (state as any)[key] = (payload as any)[key]);
        },

        setPlace: (state: SearchState, action?: any): any => {
            const { payload } = action as { payload: google.maps.places.PlaceResult };

            state.place = payload;
        },
    },
});

const counterReducer = slice.reducer;

export const { setPlace, setSearchState } = slice.actions as any;

export default counterReducer;