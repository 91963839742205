import { useTranslation } from "react-i18next";

export function useCurrentLanguage()  {
    const { i18n } = useTranslation();
    const { resolvedLanguage } = i18n;

    return resolvedLanguage;
}

export function useSwitchLanguage() {
    const { i18n } = useTranslation();

    return (code: string) => {
        i18n.changeLanguage(code);
        window.location.reload();
    };
}