import { Slice, createSlice } from '@reduxjs/toolkit';

export interface UserState {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    displayName: string;
    phone: string;
    mail: string;
    forcePassReset: boolean;
}

const initialState: UserState = {
    id: -1,
    name: '',
    firstName: '',
    lastName: '',
    displayName: '',
    phone: '',
    mail: '',
    forcePassReset: false,
};

const slice: Slice<any, any, any> = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state: UserState, action?: any): any => {
            const { payload } = action as { payload: UserState };

            Object.keys(payload).forEach(key => (state as any)[key] = (payload as any)[key]);
        },
        setUserName: (state: UserState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.name = payload;
        },
        setUserFirstName: (state: UserState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.firstName = payload;
        },
        setUserLastName: (state: UserState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.lastName = payload;
        },
        setUserPhone: (state: UserState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.phone = payload;
        },
        setUserMail: (state: UserState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.mail = payload;
        },
        setLogout: (state: UserState): void => {
            state.id = initialState.id;
            state.firstName = initialState.firstName;
            state.lastName = initialState.lastName;
            state.displayName = initialState.displayName;
            state.phone = initialState.phone;
            state.mail = initialState.mail;
            state.forcePassReset = initialState.forcePassReset;
        }
    },
});

const reducer = slice.reducer;

export const { setUser, setUserName, setUserFirstName, setUserLastName, setUserPhone, setUserMail, setLogout } = slice.actions as any;

export default reducer;