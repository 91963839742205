import { Slice, createSlice } from '@reduxjs/toolkit';

export interface CartState {
    products: { [ key: number ]: number };
    processing: number[];
    syncronized: boolean;
    coupon: string;
    couponNewsletter: string;
    mail: string;
}

const initialState: CartState = {
    products: {},
    processing: [],
    syncronized: false,
    coupon: null,
    couponNewsletter: null,
    mail: null
};

const counterSlice: Slice<any, any, any> = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setStoredCartSyncronized: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: boolean };

            state.syncronized = payload;
        },
        increment: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: number };

            if (!state.products[payload]) {
                state.products[payload] = 0;
            }

            state.products[payload]++;
        },
        decrement: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: number };

            if (state.products[payload] === 1) {
                delete state.products[payload];
            } else {
                state.products[payload]--;
            }
        },
        removeStoredCartProduct: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: number };

            delete state.products[payload];
        },
        addProcessingProduct: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: number };

            state.processing.push(payload);
        },
        removeProcessingProduct: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: number };

            state.processing = state.processing.filter(item => item !== payload);
        },
        setStoredCartCoupon: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: CartState['coupon'] };

            state.coupon = payload;
        },
        setStoredCartCouponNewsletter: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: CartState['couponNewsletter'] };

            state.couponNewsletter = payload;
        },
        setStoredCartMail: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: CartState['mail'] };

            state.mail = payload;
        },
        setStoredCartProducts: (state: CartState, action?: any): any => {
            const { payload } = action as { payload: CartState['products'] };

            state.products = payload;
        },
        emptyStoredCart(state: CartState, action?: any): any {
            Object.keys(state.products).forEach(id => {
                delete state.products[(id as any)];
            });
        }
    },
});

const counterReducer = counterSlice.reducer;

export const { setStoredCartSyncronized, increment, decrement, removeStoredCartProduct, setStoredCartProducts, emptyStoredCart,
                addProcessingProduct, removeProcessingProduct, setStoredCartCoupon, setStoredCartCouponNewsletter, setStoredCartMail } = counterSlice.actions as any;

export default counterReducer;