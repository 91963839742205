import { useState } from 'react';

export function resetCaptcha({ ref: captchaRef, onSuccess, onError, onExpire }
    : { ref: any, onSuccess: ((_: string) => void), onError?: (() => void), onExpire?: (() => void)}
) {
    grecaptcha.ready(() => {
        grecaptcha.render(captchaRef.current, {
            "sitekey": process.env.RECAPTCHA2_KEY,
            "callback": onSuccess,
            //"error-callback": onError,
            //"expired-callback": onExpire,
        });
    });
}

export function useRecaptcha(): [ boolean, (() => void) ] {
    const [isLoaded, setIsLoaded] = useState(false);
    const lang = localStorage.getItem('i18nextLng');

    return [isLoaded, () => {
        if (!isLoaded) {
            window.onLoadReacaptcha2 = () => {
                setIsLoaded(true);
            };

            var head = document.getElementsByTagName('head')[0];

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://www.google.com/recaptcha/api.js?render=explicit&hl=${lang ?? 'en'}&onload=onLoadReacaptcha2`;

            head.appendChild(script);
        }
    }];
}