import DashboardSidebarComponent from "@app/components/ui/dashboard.sidebar";
import Loader from "@app/components/ui/loader";

import '@app/scss/theme/pages/_dashboard.page.scss';
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DashboardPage = lazy(() => import('@app/pages/dashboard/dashboard.page'));
const NotificationsPage = lazy(() => import('@app/pages/notifications/notifications.page'));
const ProfilePage = lazy(() => import('@app/pages/profile/profile.page'));
const PastOrdersPage = lazy(() => import('@app/pages/past-orders/past-orders.page'));

const internalPages = [
    {
        id: 'dashboard',
        component: DashboardPage,
    },
    {
        id: 'past-orders',
        component: PastOrdersPage,
    },
    {
        id: 'profile',
        component: ProfilePage,
    },
    {
        id: 'notifications',
        component: NotificationsPage,
    },
];

export default function DashboardLayout() {
    const [component, setComponent]: [any, any] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location?.pathname) {
            const path = location.pathname.replace('/', '');
            const page = internalPages.reduce((acc: any, page: any) => path === page.id ? page : acc, internalPages[0]);

            setComponent(page);
        }
    }, [location?.pathname]);

    return (
        <main className="fixed-nav sticky-footer internal">
            <div className="container">
                <div className="row">
                    <div className="col-3 d-sm-none d-lg-block menu">
                        <DashboardSidebarComponent active={component?.id || 'dashboard'} />
                    </div>
                    <div className="col-lg-9 content">
                        <Loader loading={!component}>
                            {component !== null ? <Suspense>{React.createElement(component.component, {})}</Suspense>  : null}
                        </Loader>
                    </div>
                </div>
            </div>
        </main>
    );
}