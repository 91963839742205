import { configureStore } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import checkoutReducer from '@app/store/checkout.store';
import cartReducer from '@app/store/cart.store';
import searchReducer from '@app/store/search.store';
import userReducer from '@app/store/user.store';
import textSearchReducer from "@app/store/text.search.store";

const reducers = combineReducers({
    checkout: checkoutReducer,
    cart: cartReducer,
    search: searchReducer,
    user: userReducer,
    textSearch: textSearchReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['checkout', 'search', 'user', 'cart', 'textSearch'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch