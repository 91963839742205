import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';

import UserService from '@app/services/user.service';
import { emailRegex } from "@app/utils/validations";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Loader from '../ui/loader';
import { useHandleLogin } from '@app/utils/user';
import { hideGlobalModal } from '@app/utils/modals';

export default function LoginModalComponent(props?: any) {
    const [showLoginError, setShowLoginError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();
    const handleLogin = useHandleLogin();

    const hasUserChangedCreds = props && props.hasUserChangedCreds;

    const { register, handleSubmit, clearErrors, formState: { errors }, reset } = useForm({
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        criteriaMode: 'firstError',
    });

    useEffect(() => {
        if (hasUserChangedCreds) {
            localStorage.setItem('relogin', 'true');
            localStorage.removeItem('jwt_token');
        }
    }, []);

    const onSubmit = (data: any) => {
        clearErrors();

        setIsLoading(true);

        if (data.email && data.password) {
            UserService
                .login({ username: data.email, password: data.password })
                .then((response: any) => {
                    if (response.statusCode === 200) {
                        handleLogin(response.data, () => {
                            hideGlobalModal('login');

                            setShowLoginError(false);
                            reset();
                            window.location.pathname = '/home';
                        });
                    } else {
                        setShowLoginError(true);
                    }
                })
                .catch(err => setShowLoginError(true))
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <div>
            <Dialog
                open={true}
                fullWidth={true}
                className="mui-modal-login"
                onClose={() => hasUserChangedCreds ? undefined : hideGlobalModal('login')}>
                <DialogContent>
                    <div className="modal_header bg_gray text-center w-100 py-3 m-0">
                        <h3 className='m-0'>{t('SIGN_IN')}</h3>
                    </div>
                    <div id="sign-in-dialog" className="zoom-anim-dialong">
                        {hasUserChangedCreds
                            ? <div className="text-center mb-4">
                                <h5>{props.header}</h5>
                                <p>{props.body}</p>
                            </div>
                            : null}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="sign-in-wrapper">
                                <div className={`form-group ${errors['email'] ? 'error' : ''}`}>
                                    <label htmlFor="email">{t('YOUR_EMAIL_PLACEHOLDER')}</label>
                                    <input className="form-control"
                                        name="email"
                                        type="email"
                                        id="email"
                                        {...register('email', {
                                            required: t('Полето е задължително'),
                                            pattern: {
                                                value: emailRegex,
                                                message: t('INVALID_EMAIL_MESSAGE'),
                                            }
                                        })} />
                                    <i className="icon_mail_alt"></i>
                                    <div className="error-message mt-2">
                                        <ErrorMessage errors={errors} name="email" />
                                    </div>
                                </div>
                                <div className={`form-group ${errors['password'] ? 'error' : ''}`}>
                                    <label htmlFor="password">{t('YOUR_PASSWORD_PLACEHOLDER')}</label>
                                    <input className="form-control"
                                        type="password"
                                        name="password"
                                        id="password"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        spellCheck="true"
                                        {...register('password', {
                                            required: t('Полето е задължително'),
                                            /*
                                            pattern: process.env.ENV === 'production' ? {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                                                message: t('INVALID_PASS')
                                            } : null */
                                        })} />
                                    <i className="icon_lock_alt"></i>
                                    <div className="error-message mt-2">
                                        <ErrorMessage errors={errors} name="password" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <span className={"login-error text-danger" + (showLoginError ? '' : ' d-none')}>{t('INVALID_LOGIN')}</span>
                                </div>
                                {hasUserChangedCreds
                                    ? null
                                    : <div className="clearfix add_bottom_15">
                                        <div className="checkboxes float-start"></div>
                                        <div className="float-end"><Link to="/lost-password" id="forgot" onClick={() => hideGlobalModal('login')}>{t('Забравена парола')}?</Link></div>
                                    </div>}
                                <Loader loading={isLoading}>
                                    <div className="text-center">
                                        <input type="submit" value={t('SIGN_IN')} className="btn_1 full-width mb_5" />
                                        {hasUserChangedCreds
                                            ? null
                                            : <p className="mt-3">{t('DONT_HAVE_AN_ACCOUNT')} <Link to="/sign-up" onClick={() => hideGlobalModal('login')}>{t('SIGN_UP')}</Link></p>}
                                    </div>
                                </Loader>
                            </div>
                        </form>
                        {hasUserChangedCreds
                            ? null
                            : <button
                                title="Close (Esc)"
                                type="button"
                                className="mfp-close"
                                onClick={() => hideGlobalModal('login')}></button>}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}