import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";
import { Dialog, DialogContent } from "@mui/material";

import { useStoredUser } from "@app/store/selectors";
import { setUser } from "@app/store/user.store";
import UserService from "@app/services/user.service";
import { passwordRegex } from "@app/utils/validations";

import Loader from "@app/components/ui/loader";

export default function PasswordResetModalComponent() {
    const dispatch = useDispatch();
    const storedUser = useStoredUser();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, clearErrors, watch, formState: { errors }, setError } = useForm({
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        criteriaMode: 'firstError',
    });

    const onSubmit = (data: any) => {
        clearErrors();
        setIsLoading(true);

        UserService
            .forcePasswordChange(data)
            .then(() => {
                dispatch(setUser({ ...storedUser, forcePassReset: false }));
            })
            .catch(err => {
                let name = 'newPass';
                let msg = t('INVALID_PASS_PLACEHOLDER');

                if (err.status === 403) {
                    msg = t('INVALID_PASS');
                } else if (err.status === 405) {
                    name = 'newPassConfirm';
                    msg = t('INVALID_IDENTICAL_PASS_PLACEHOLDER');
                }

                setError(name, { type: 'validate', message: msg });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div>
            <Dialog
                open={true}
                fullWidth={true}
                className="mui-modal-login">
                <DialogContent>
                    <div className="modal_header bg_gray text-center w-100 py-3 m-0">
                        <h3 className='m-0'>{t('Reset password')}</h3>
                    </div>
                    <div id="sign-in-dialog" className="zoom-anim-dialong py-4">
                        <p className={`text-center`}>{t('FORCE_PASS_RESET_PLACEHOLDER')}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="sign-in-wrapper">
                                <div className={`form-group ${errors['newPass'] ? 'error' : ''}`}>
                                    <label htmlFor="newPass">{t('YOUR_NEW_PASSWORD_PLACEHOLDER')}</label>
                                    <input className="form-control"
                                        type="password"
                                        name="newPass"
                                        id="newPass"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        spellCheck="true"
                                        {...register('newPass', {
                                            required: t('Полето е задължително'),
                                            pattern: process.env.ENV === 'production' ? {
                                                value: passwordRegex,
                                                message: t('INVALID_PASS')
                                            } : null
                                        })} />
                                    <i className="icon_lock_alt"></i>
                                    <div className="error-message mt-2">
                                        <ErrorMessage errors={errors} name="newPass" />
                                    </div>
                                </div>
                                <div className={`form-group ${errors['newPassConfirm'] ? 'error' : ''}`}>
                                    <label htmlFor="newPassConfirm">{t('YOUR_NEW_PASSWORD_CONFIRM_PLACEHOLDER')}</label>
                                    <input className="form-control"
                                        type="password"
                                        name="newPassConfirm"
                                        id="newPassConfirm"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        spellCheck="true"
                                        {...register('newPassConfirm', {
                                            required: t('Полето е задължително'),
                                            validate: (val: string) => {
                                                if (watch('newPass') !== val) {
                                                    return t('PASS_NOT_MATCH');
                                                }
                                            }
                                        })} />
                                    <i className="icon_lock_alt"></i>
                                    <div className="error-message mt-2">
                                        <ErrorMessage errors={errors} name="newPassConfirm" />
                                    </div>
                                </div>
                                <Loader loading={isLoading}>
                                    <div className="text-center">
                                        <input type="submit" value={t('Смени')} className="btn_1 full-width mb_5" />
                                    </div>
                                </Loader>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}