import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setUser } from '@app/store/user.store';
import { useStoredUser } from "@app/store/selectors";

export function useHandleLogin() {
    const dispatch = useDispatch();
    const storedUser = useStoredUser();

    return (response: any, callback?: (() => void)) => {
        dispatch(setUser({
            ...storedUser,
            id: response.id,
            name: response.nicename,
            mail: response.email,
            firstName: response.firstName,
            lastName: response.lastName,
            displayName: response.displayName,
            forcePassresponseet: response.forcePassreset,
        }));

        localStorage.removeItem('relogin');
        localStorage.setItem('jwt_token', response.token);

        if (callback) {
            callback();
        }
    };
}

export default function useIsUserLoggedIn(): boolean {
    const [is, setIs] = useState(hasJwt);

    function hasJwt() {
        return localStorage.getItem('jwt_token') && localStorage.getItem('jwt_token').length;
    }

    useEffect(() => {
        setIs(hasJwt());
    }, [localStorage.getItem('jwt_token')]);

    return !!is;
}