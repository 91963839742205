import React, { lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Page, RestrictedPage } from '@app/components/utils/page';
import Header from '@app/components/header';
import Footer from '@app/components/footer';

import { useStoredUser } from './store/selectors';
import { useIsEmbedded } from './utils/location';
import MailingService from './services/mailing.service';
import DashboardLayout from './pages/dashboard/dashboard.layout';
import SearchAllPage from './pages/search-all/search.all.page';
import GlobalModals, { showGlobalModal } from './utils/modals';

const HomePage = lazy(() => import('@app/pages/home/home.page'));
const VendorPage = lazy(() => import('@app/pages/vendor/vendor.page'));
const ProductPage = lazy(() => import('@app/pages/product/product.page'));
const SearchPage = lazy(() => import('@app/pages/search/search.page'));
const ConfirmationPage = lazy(() => import('@app/pages/confirmation/confirmation.page'));
const CheckoutFinishPage = lazy(() => import('@app/pages/confirmation/checkout-finish.page'));
const CheckoutPage = lazy(() => import('@app/pages/checkout/checkout.page'));
const PrivacyPage = lazy(() => import('@app/pages/privacy/privacy.page'));
const GEPPage = lazy(() => import('@app/pages/gep/gep.page'));
const TermsPage = lazy(() => import('@app/pages/terms/terms.page'));
const NotFoundPage = lazy(() => import('@app/pages/404/404.page'));
const JoinPage = lazy(() => import('@app/pages/join/join.page'));
const PartnersPage = lazy(() => import('./pages/partners/partners.page'));
const ContactPage = lazy(() => import('@app/pages/contact/contact.page'));
const CityLandingPage = lazy(() => import('@app/pages/landing/city.landing.page'));
const BlogPage = lazy(() => import('@app/pages/blog/blog.page'));
const BlogPostPage = lazy(() => import('@app/pages/blog/blog-post.page'));
const AboutPage = lazy(() => import('@app/pages/about/about.page'));
const SignUpPage = lazy(() => import('@app/pages/sign-up/sign-up.page'));
const SigningPage = lazy(() => import('@app/pages/signing/singing.page'));
const FAQPage = lazy(() => import('@app/pages/faq/faq.page'));
const LostPasswordPage = lazy(() => import('@app/pages/password/lost-password.page'));
const ResetPasswordPage = lazy(() => import('@app/pages/password/reset-password.page'));

const BuilderLandingPage = lazy(() => import('@app/pages/builderio/landing.page'));

function AppRoutes() {
    const location = useLocation();
    const user = useStoredUser();
    const isEmbedded = useIsEmbedded();

    const [stickyHeader, setStickyHeader] = useState(true);
    const [showScroll, setShowScroll] = useState(false);

    const { t } = useTranslation();

    const hasScroll = (current: number) => {
        return window.outerHeight * 1.5 < Math.abs(current);
    };

    useEffect(() => {
        if (localStorage.getItem('relogin')) {
            showGlobalModal('login', {
                hasUserChangedCreds: true,
                header: t('SUCCESS_CRED_UPDATE_GENERIC'),
                body: t('RELOGIN_REQUIRED'),
            });
        }

        setTimeout(() => {
            const newsletterPopup = localStorage.getItem('newsletter_popup');

            if (!newsletterPopup) {
                if (user.mail) {
                    MailingService.hasSub(user.mail)
                        .then((res: any) => {
                            if (!res.hasSub) {
                                showGlobalModal('newsletter');
                            } else {
                                localStorage.setItem('newsletter_popup', `${Date.now()}`);
                            }
                        });
                } else {
                    showGlobalModal('newsletter');
                }
            }
        }, 30000);
    }, []);

    useEffect(() => {
        const isSticky = ['/', '/partners', '/about'].includes(location.pathname)
                    || location.pathname.startsWith('/vendor/');
        const isJsonLdPage = [/^\/blog\/.+$/g, /^\/faq$/g, /^\/partners$/g, /^\/product\/.+$/g].some(path => path.test(location.pathname));

        if (!isJsonLdPage) {
            document.querySelector('#schemaorg')?.remove();
        }

        setStickyHeader(isSticky);
    }, [location]);

    useScrollPosition(({ prevPos, currPos }) => {
        const has = hasScroll(currPos.y);
        setShowScroll(has);
    }, [], null, false, 500);

    return (
        <>
            {/*<Suspense fallback={<NotFoundPage />}>*/}
                {isEmbedded ? null : <Header sticky={stickyHeader} />}

                <Routes>
                    <Route path="/" element={<Page><HomePage /></Page>} />
                    <Route path="/product/:id" element={<Page title={t('Продукт')}><ProductPage /></Page>} />
                    <Route path="/vendor/:id" element={<Page title={t('Партньор')}><VendorPage /></Page>} />
                    <Route path="/blog/:id" element={<Page title={t('Блог пост')}><BlogPostPage /></Page>} />
                    <Route path="/offers" element={<Page title={t('Оферти')}><SearchPage /></Page>}/>
                    <Route path="/offers/*" element={<Page title={t('Оферти')}><SearchPage /></Page>}/>
                    <Route path="/search/*" element={<Page title={t('Търсене')}><SearchAllPage /></Page>} />
                    <Route path="/checkout" element={<Page title={t('Поръчка')}><CheckoutPage /></Page>} />
                    <Route path="/checkout-finish/:id" element={<Page title={t('Обработване на поръчка')}><CheckoutFinishPage /></Page>} />
                    <Route path="/confirmation/:id" element={<Page title={t('Успешна поръчка')}><ConfirmationPage /></Page>} />
                    <Route path="/lost-password" element={<Page title={t('Забравена парола')}><LostPasswordPage /></Page>} />
                    <Route path="/reset-password" element={<Page title={t('Нова парола')}><ResetPasswordPage /></Page>} />

                    <Route path="/privacy" element={<Page title={t('Политика по поверителност')}><PrivacyPage /></Page>} />
                    <Route path="/terms" element={<Page title={t('Общи условия')}><TermsPage /></Page>} />
                    <Route path="/gep" element={<Page title={'Gender Equality Plan 2024'}><GEPPage /></Page>} />
                    <Route path="/partners" element={<Page title={t('Стани партньор')}><JoinPage /></Page>} />
                    <Route path="/vendors" element={<Page title={t('Партньори')}><PartnersPage /></Page>} />
                    <Route path="/blog" element={<Page title={t('Блог')}><BlogPage /></Page>} />
                    <Route path="/faq" element={<Page title={t('FAQ')}><FAQPage /></Page>} />
                    <Route path="/about" element={<Page title={t('За нас')}><AboutPage /></Page>} />
                    <Route path="/contact" element={<Page title={t('Контакти')}><ContactPage /></Page>} />

                    <Route path="/l/burgas" element={<Page title={t('COMING_TO_BURGAS')}><CityLandingPage /></Page>} />
                    <Route path="/l/starazagora" element={<Page title={t('COMING_TO_SZ')}><CityLandingPage /></Page>} />
                    <Route path="/l/*" element={<Page><BuilderLandingPage /></Page>} />

                    <Route path="/sign-up" element={<RestrictedPage title={t('SIGN_UP')} restrictForAuth={true} component={SignUpPage} />}  />

                    <Route path="/home" element={<RestrictedPage title={t('Аз Foody')} component={DashboardLayout} />} />
                    <Route path="/past-orders" element={<RestrictedPage title={t('Моите поръчки')} component={DashboardLayout} />} />
                    <Route path="/profile" element={<RestrictedPage title={t('Моят профил')} component={DashboardLayout} />} />
                    <Route path="/notifications" element={<RestrictedPage title={t('Известия')} component={DashboardLayout} />} />

                    <Route path="/signing" element={<Page title={t('SIGNING')}><SigningPage /></Page>} />
                    <Route path="/not-found" element={<Page title="404"><NotFoundPage /></Page>} />
                    <Route path="*" element={<Navigate to="/not-found" replace={true} />} />
                </Routes>

                {isEmbedded ? null : <Footer />}

                <GlobalModals />

                <div
                    id="toTop"
                    className={showScroll ? 'visible' : ''}
                    onClick={() => window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    })}></div>
            {/*</Suspense>*/}
        </>
    );
}

export default AppRoutes;