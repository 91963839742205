import { img } from "@app/style/utils";

export default function FollowLinksComponent({ className }: { className?: string }) {
    return (
        <ul className={`follow_us ${className}`}>
            <li>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Foody.too">
                    <img src={img('facebook_icon.svg')} alt="" />
                </a>
            </li>
            <li>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/foody.to_bg">
                    <img src={img('instagram_icon.svg')} alt="" />
                </a>
            </li>
            <li>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/foodyto">
                    <img src={img('linkedin_icon.svg')} alt="" />
                </a>
            </li>
            <li>
                <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@foody.to">
                    <img src={img('tiktok_icon.png')} alt="" />
                </a>
            </li>
        </ul>
    );
}