import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from '@app/app';
import { store } from '@app/store';

import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import i18nBG from '@app/i18n/bg.json';
import i18nEN from '@app/i18n/en.json';

(window as any).appLanguages = ['bg', 'en'];

if (process.env.ENV === 'production') {
    Sentry.init({
        dsn: "https://b5841b2d726e43f9b1eaeb14440bcd75@o1095418.ingest.sentry.io/6114915",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            bg: {
                translation: i18nBG,
            },
            en: {
                translation: i18nEN,
            }
        },

        detection: {
            order: ['localStorage']
        },

        // lng: 'bg', // if you're using a language detector, do not define the lng option
        fallbackLng: {
            'bg': ['bg', 'en'],
            'default': ['bg'],
        },

        supportedLngs: ['bg', 'en'],

        interpolation: {
            escapeValue: false
        }
    });

window.onscroll = function() {
    const body = document.getElementsByTagName('body')[0];
    const header = document.getElementsByClassName('header')
                        ? document.getElementsByClassName('header')[0]
                        : null;

    if (window.pageYOffset === 0) {
        body.classList.remove('is-scrolled', 's-100', 's-200', 's-300', 's-400', 's-500', 's-600');
    } else if (window.pageYOffset > 600) {
        body.classList.add('is-scrolled', 's-100', 's-200', 's-300', 's-400', 's-500', 's-600');
    } else if (window.pageYOffset > 500) {
        body.classList.remove('s-600');
        body.classList.add('is-scrolled', 's-100', 's-200', 's-300', 's-400', 's-500');
    } else if (window.pageYOffset > 400) {
        body.classList.remove('s-500', 's-600');
        body.classList.add('is-scrolled', 's-100', 's-200', 's-300', 's-400');
    } else if (window.pageYOffset > 300) {
        body.classList.remove('s-400', 's-500', 's-600');
        body.classList.add('is-scrolled', 's-100', 's-200', 's-300');
    } else if (window.pageYOffset > 200) {
        body.classList.remove('s-300', 's-400', 's-500', 's-600');
        body.classList.add('is-scrolled', 's-100', 's-200');
    } else if (window.pageYOffset > 100) {
        body.classList.remove('s-200', 's-300', 's-400', 's-500', 's-600');
        body.classList.add('is-scrolled', 's-100');
    } else if (window.pageYOffset > 0) {
        body.classList.add('is-scrolled');
    }

    if (header) {
        if (window.pageYOffset === 0) {
            header.classList.remove('dark');
            header.classList.remove('sticky');
        } else {
            header.classList.add('dark');
            header.classList.add('sticky');
        }
    }
};

const persistor = persistStore(store);
const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);
