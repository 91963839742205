import { useState } from "react";
import { useDispatch } from "react-redux";

import { setPlace } from "@app/store/search.store";
import { Autocomplete } from "@react-google-maps/api";
import { useGoogle } from "@app/utils/google";
import { useTranslation } from "react-i18next";

export default function LocationSearchComponent({ onSubmit }: { onSubmit?: () => void }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isLoaded] = useGoogle();
    const [autocomplete, setAutocomplete] = useState(null);

    function haltEnterKey(e: any) {
        if ((e.key as string).toLowerCase() === 'enter') {
            e.preventDefault();
        }
    }

    function onSubmitForm(e: any) {
        e.preventDefault();
        const place = autocomplete.getPlace();
        dispatch(setPlace(place));
        onSubmit();
    }

    return (
        <div className="search_bar_list">
            <form onKeyDown={haltEnterKey} onSubmit={onSubmitForm}>
                {isLoaded &&
                    <Autocomplete
                        options={{
                            componentRestrictions: {
                                country: ['bg'],
                            },
                            types: ['street_address', 'street_number', 'postal_code', 'neighborhood', 'point_of_interest'],
                        }}
                        onLoad={autocomplete => setAutocomplete(autocomplete)}>
                        <input type="text" className="form-control" placeholder={t('SEARCH_BY_ADDRESS_PLACEHOLDER')} />
                    </Autocomplete>}
                <button type="submit">{t('Търсене')}</button>
            </form>
        </div>
    );
}