import { IOrder } from './../entities/order.entity';
import { apiDelete, apiGet, apiPost, apiPut } from "@app/utils/http";

import { IVendor } from '@app/entities/vendor.entity';
import { IProduct } from '@app/entities/product.entity';

export interface ICartTotal {
    subtotal: number;
    discountTotal: number;
    shippingTotal: number;
    total: number;
}

export interface ICartShippingOption {
    cost: number;
    methodId: string;
    methodInstanceId: number;
    name: string;
    disabled: boolean;
    carryout: boolean;
    minCart: number;
}

export interface ICartItem {
    id: number;
    wcId: number;
    name: string;
    altName: string;
    // price: string;
    quantity: number;
    subtotal: number;
    total: number;
    product: IProduct;
    shippingClass: string
}

export interface ICartNotices {
    error?: string[];
}

export interface ICartCoupon {
    coupon: string;
    savingFloat: number;
    type: string
}

export interface ICartVendor extends IVendor {
    shippable: { [key: string]: boolean };
    shippableValue: number;
    shippingCost: { [key: string]: number };
    estimatedTime: string;
}

export interface ICartResponse {
    items: ICartItem[];
    itemCount: number;
    coupons: ICartCoupon[];
    currency: string;
    totals: ICartTotal;
    needsShipping: boolean;
    needsPayment: boolean;
    notices: ICartNotices;
    vendors: ICartVendor[];
    shippingOptions: ICartShippingOption[];

    couponAddedResult?: any;
    couponRemovedResult?: any;
}

export interface ICheckoutRequest {
    stripeToken?: string;
    shippingClasses?: string[];
    orderId: string | number;
    user: {
        name: string;
        mail: string;
        phone: string;
        city: string;
        zip: string;
        address: string;
        addressNote: string;
        password: string;
    };
}

export interface ICheckoutResponse {
    stripeClientSecret: string;
    orderId: number;
    user?: {
        jwt: string;
        id: number;
        name: string;
        firstName: string;
        lastName: string;
        mail: string;
    }
    errors?: { [key: string]: string[] };
}

export default class CartService {
    static get(): Promise<ICartResponse> {
        return apiGet(`@api/cart`, )
                .then(response => (response as any) as ICartResponse);
    }

    static customer(data: any): Promise<ICheckoutResponse> {
        return apiPost(`@api/cart/customer`, data)
                .then(response => (response as any) as ICheckoutResponse);
    }

    static intent(data: ICheckoutRequest): Promise<ICheckoutResponse> {
        return apiPost(`@api/cart/intent`, data)
                .then(response => (response as any) as ICheckoutResponse);
    }

    static failed(data: any): Promise<ICheckoutResponse> {
        return apiPost(`@api/cart/failed`, data)
                .then(response => (response as any) as ICheckoutResponse);
    }

    static complete(data: { order_id: string }): Promise<ICheckoutResponse> {
        return apiPost(`@api/cart/complete`, data)
                .then(response => (response as any) as ICheckoutResponse);
    }

    static payExisting(id: number, data: ICheckoutRequest): Promise<ICheckoutResponse> {
        return apiPost(`@api/cart/pay/${id}`, data)
                .then(response => (response as any) as ICheckoutResponse);
    }

    static shipping(id: ICartShippingOption): Promise<ICheckoutResponse> {
        return apiPost('@api/cart/shipping', id)
            .then(response => (response as any) as ICheckoutResponse);
    }

    static addCartItem(id: number, quantity: number): Promise<ICheckoutResponse> {
        return apiPut(`@api/cart/item/${id}`, {quantity: quantity})
                .then(response => (response as any) as ICheckoutResponse);
    }

    static updateItem(id: number, quantity: number): Promise<Response> {
        return apiPost(`@api/cart/item/${id}`, { quantity: quantity });
    }

    static removeItem(id: number): Promise<Response> {
        return apiDelete(`@api/cart/item/${id}`);
    }

    static addCoupon(code: string): Promise<ICartResponse> {
        return apiPost(`@api/cart/coupon/${code}`, {})
                .then(response => (response as any) as ICartResponse);
    }

    static removeCoupon(code: string): Promise<ICartResponse> {
        return apiDelete(`@api/cart/coupon/${code}`)
                .then(response => (response as any) as ICartResponse);
    }

    static confirm(orderId: number): Promise<IOrder> {
        return apiGet(`@api/cart/confirm/${orderId}`)
                .then(order => (order as any) as IOrder);
    }
}