export function buildHeaders(auth: boolean) {
    const storedLanguage = localStorage.getItem('i18nextLng');
    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': storedLanguage && window.appLanguages.includes(storedLanguage) ? storedLanguage : window.appLanguages[0],
    });

    if (auth) {
        const jwt = localStorage.getItem('jwt_token');
        headers.append('Authorization', jwt ? 'Bearer ' + jwt : '');
    }

    return headers;
}

export function buildRequest(method: string, auth?: boolean, data?: object, signal?: AbortSignal) {
    return {
        method: method,
        signal: signal,
        headers: buildHeaders(auth),
        credentials: 'include' as RequestCredentials,
        ...(data && {body: JSON.stringify(data)}),
    };
}

export function apiRequest(
    request: RequestInfo,
    init?: RequestInit,
    allowUnauthenticatedRequest?: boolean
): Promise<Response> {
    const map: { [key:string]: string } = {
        '@api': process.env.API_BASE + 'api',
        '@blog': process.env.API_BASE + 'api/blog',
        '@auth': process.env.API_BASE + 'wp-json/jwt-auth/v1',
        '@cocart': process.env.API_BASE + 'wp-json/cocart',
    };

    const requestInfo: string | Request = typeof request === 'string' || request instanceof String
                            ? Object.keys(map).reduce((acc, key) => acc.replace(key, map[key]), request)
                            : request;

    return fetch(requestInfo, init)
                .then(response => {
                    if (response.status === 401) {
                        localStorage.removeItem('jwt_token');
                        window.location.reload();
                    } else if (response.status === 412) {
                            response.json()
                                .then(res => {
                                    localStorage.setItem('jwt_token', res.Bearer.data.token);

                                    localStorage.setItem('id', res.Bearer.data.id);
                                    localStorage.setItem('name', res.Bearer.data.displayName);
                                    localStorage.setItem('mail', res.Bearer.data.email);
                                    localStorage.setItem('firstName', res.Bearer.data.firstName);
                                    localStorage.setItem('lastName', res.Bearer.data.lastName);

                                    window.location.pathname = '/signing';
                                });
                    } else if (response.status === 418) {
                        localStorage.removeItem('jwt_token');
                        localStorage.removeItem('persist:root');
                        window.location.pathname = '/';
                    }

                    return response;
                });
}

export function apiGet(
    request: RequestInfo,
    allowUnauthenticated?: boolean
): Promise<Response> {
    return apiRequest(request, buildRequest('GET', !allowUnauthenticated), allowUnauthenticated)
        .then(response => {
            if (response.status !== 200) {
                throw response;
            }

            return response;
        })
        .then(response => response.json());
}

export function apiPut(
    request: RequestInfo,
    data: any,
    allowUnauthenticated?: boolean
): Promise<Response> {
    return apiRequest(request, buildRequest('PUT', !allowUnauthenticated, data), allowUnauthenticated)
        .then(response => {
            if (response.status >= 400) {
                throw response;
            }

            return response;
        })
        .then(response => response.json());
}

export function apiPost(
    request: RequestInfo,
    data: any,
    allowUnauthenticated?: boolean,
    signal?: AbortSignal
): Promise<Response> {
    return apiRequest(request, buildRequest('POST', !allowUnauthenticated, data, signal), allowUnauthenticated)
        .then(response => {
            if (response.status >= 400) {
                throw response;
            }

            return response;
        })
        .then(response => response.json());
}

export function apiDelete(
    request: RequestInfo,
    data?: any,
    allowUnauthenticated?: boolean,
    signal?: AbortSignal
): Promise<Response> {
    return apiRequest(request, buildRequest('DELETE', !allowUnauthenticated, data, signal), allowUnauthenticated)
        .then(response => response.json());
}
