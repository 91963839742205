export function serverImage(route: string) {
    return `${process.env.API_BASE}img/${route}`;
}

export function img(route: string) {
    return `${process.env.BASE_THEME_PATH}/img/${route}`;
}

export function imgUrl(route: string) {
    return `url(${process.env.BASE_THEME_PATH}/img/${route})`;
}

export function uploadsImg(route: string) {
    return `${process.env.API_BASE}wp-content/uploads/${route}`;
}
export function uploadsImgUrl(route: string) {
    return `url(${process.env.API_BASE}wp-content/uploads/${route})`;
}
