import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryParam } from "@app/utils/navigation";

import '@app/scss/theme/components/_search-all.component.scss';

export function useUrlPageContext(): string {
    const location = useLocation();
    let context = location.pathname.replace('/', '');
    const endIndex = context.indexOf('/');

    if (endIndex > 0) {
        context = context.substring(0, endIndex);
    }

    return context;
}

export default function SearchAllBar() {
    const context = useUrlPageContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [type, setType] = useState<'products' | 'all'>(context === 'offers' ? 'products' : 'all');
    const [text, setText] = useState<string>('');
    const query = useQueryParam('query');

    useEffect(() => {
        if (query) {
            setText(query);
        }
    }, [query]);

    function onSubmit(e: any) {
        e.preventDefault();

        if (type === 'products') {
            navigate(`/offers${text ? `?query=${text}` : '' }`);
        } else {
            navigate(`/search?q=${text}`);
        }
    }

    if (window.elastic !== true) {
        return null;
    }

    return (
        <div className="search_bar_list">
            <form onSubmit={onSubmit}>
                <input
                    className="form-control no_border_r"
                    type="text"
                    onChange={(e) => setText(e.target.value)}
                    value={text} />
                <div className="search-type-container">
                    {type === "products"
                        ? <button type="button" onClick={() => setType('all')}>{t('SEARCH.products')}</button>
                        : <button type="button" onClick={() => setType('products')}>{t('SEARCH.site')}</button>}
                </div>
                <button type="submit">{t('Търсене')}</button>
            </form>
        </div>
    );
}