import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";

function waitForElementToDisplay(selector: any, callback: any, checkFrequencyInMs: any, timeoutInMs: any) {
    var startTimeInMs = Date.now();

    (function loopSearch() {
        try {
            if (document.querySelector(selector) != null) {
                callback();
                return;
            } else {
                setTimeout(function () {
                    if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
                        return;
                    }

                    loopSearch();
                }, checkFrequencyInMs);
            }
        } catch (e) {}
    })();
}

function scrollToTargetAdjusted(hash: string){
    var element = document.getElementById(hash);
    var headerOffset = 70;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

function scrollToHashId () {
    // get URL hash (minus the hash mark)
    const hash = window.location.hash.substring(1);

    // if there's a hash, scroll to that ID
    if (hash && hash.length) {
        waitForElementToDisplay("#" + hash, function() {
            scrollToTargetAdjusted(hash);
            // clean up the hash, so we don't scroll on every prop update
            removeHash();
        }, 1000, 20000);
    }
}

// borrowed from http://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-with-javascript-without-page-refresh/5298684#5298684
function removeHash () {
    const loc = window.location;
    const hist = window.history;

    // use modern browser history API
    if (hist && 'pushState' in hist) {
        hist.replaceState('', document.title, loc.pathname + loc.search);
    // fallback for older browsers
    } else {
        // prevent scrolling by storing the page's current scroll offset
        const scrollV = document.body.scrollTop;
        const scrollH = document.body.scrollLeft;

        loc.hash = '';

        // restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

function ScrollToTop({ children }: {  children: JSX.Element | JSX.Element[] }) {
    const location = useLocation();

    useEffect(() => {
        if (location.state?.noScroll) {
            return;
        }

        if (location.hash.startsWith('#')) {
            scrollToHashId();
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <Fragment>{children}</Fragment>;
}

// @ts-ignore
export default ScrollToTop;