import { apiGet } from "@app/utils/http";

export const PRODUCT_TYPE = 'product';
export const VENDOR_TYPE = 'vendor';
export const BLOG_TYPE = 'blogPost';

export interface ITextSearchResponse {
    [PRODUCT_TYPE]: ITextSearchEntity[];
    [VENDOR_TYPE]: ITextSearchEntity[];
    [BLOG_TYPE]: ITextSearchEntity[];
}

export type ENTITY_TYPE = typeof PRODUCT_TYPE | typeof VENDOR_TYPE | typeof BLOG_TYPE;

export interface ITextSearchEntity {
    type: ENTITY_TYPE;
    id?: number;
    wcId: number;
    name: string;
    description: string;
    slug: string;
    image?: string; // only for blog posts
    settings?: ITextSearchSettings // only for products
}

export interface ITextSearchSettings {
    price: number;
    priceSale: number;
    quantity: number;
    status: string;
}

export default class TextSearchService {
    static get(text: string): Promise<ITextSearchResponse> {
        return apiGet(`@api/search?text=${text}`).then((data: any) => {
            let newData: ITextSearchResponse = { [PRODUCT_TYPE]: [], [VENDOR_TYPE]: [], [BLOG_TYPE]: [] };
            data.forEach((entity: ITextSearchEntity) => {
                if (entity.type === PRODUCT_TYPE) {
                    newData[PRODUCT_TYPE].push(entity);
                } else if (entity.type === VENDOR_TYPE) {
                    newData[VENDOR_TYPE].push(entity);
                } else {
                    newData[BLOG_TYPE].push(entity);
                }
            });

            return newData;
        });
    }
}