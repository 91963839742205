import React, { useEffect, useState } from 'react';

import LoginModalComponent from '@app/components/modals/login.modal';
import NewsletterModalComponent from '@app/components/modals/newsletter.modal';
import PasswordResetModalComponent from '@app/components/modals/password-reset.modal';
import AddressModalComponent from '@app/components/modals/address.modal';

export let showGlobalModal = (identifier: string, props?: any) => {};
export let hideGlobalModal = (identifier: string) => {};

export interface IModal {
    component: () => JSX.Element;
    props: { [key: string]: any };
}

const modals: { [key: string]: IModal } = {
    login: {
        component: LoginModalComponent,
        props: {},
    },
    newsletter: {
        component: NewsletterModalComponent,
        props: {},
    },
    passReset: {
        component: PasswordResetModalComponent,
        props: {},
    },
    address: {
        component: AddressModalComponent,
        props: {},
    },
};

export default function GlobalModals() {
    const [opened, setOpened]: [string[], ((_: string[]) => void)] = useState([]);

    useEffect(() => {
        showGlobalModal = show;
        hideGlobalModal = hide;
    });

    function show(identifier: string, props?: any) {
        modals[identifier].props = props ?? {};
        setOpened(opened.concat([identifier]));
    }

    function hide(identifier: string) {
        modals[identifier].props = {};
        setOpened(opened.filter(i => i !== identifier));
    }

    return (
        <>
            {opened.map((key: string, index: number) =>
                <div key={index}>
                    {React.createElement(modals[key].component, modals[key].props)}
                </div>)}
        </>
    );
}