import { useSetPageTitle } from "@app/utils/func";
import React, { Suspense, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

function Page(props: any): any {
    const location = useLocation();
    const setTitle = useSetPageTitle();

    const [path, setPath] = useState(extract() || null);

    function extract() {
        if (location?.pathname) {
            const split = location.pathname.split('/');
            const base = split[1] || 'home';

            return base;
        }
    }

    useEffect(() => {
        const base = extract();

        if (base !== path) {
            setPath(base);
        }
    }, [location?.pathname]);

    useEffect(() => {
        const page = document.getElementById('page');

        page.className = path;
    }, [path]);

    useEffect(() => {
        setTitle(props.title || 'Foody');
    }, [props?.title]);

    return <Suspense>{React.createElement(props.children.type, {}, props.children)}</Suspense>;
}

function RestrictedPage({ title, component, restrictForAuth, children }
    : { title: string, component: any, restrictForAuth?: boolean, children?: any }): any {

    const logged = localStorage.getItem('jwt_token');

    /* If restrictForAuth is true, logged-IN users cannot access guest-only pages (like sign-up) */
    if ((logged && restrictForAuth) || (!logged && !restrictForAuth)) {
        return <Navigate to="/" replace={true} />;
    }

    const props = {
        title,
        children: {
            type: component,
            ...children
        }
    };

    return (
        <Page {...props} />
    );
}

export {
    Page,
    RestrictedPage
};