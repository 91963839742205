import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import { IGeoCoordinates } from '@app/interfaces/geo.interface';

export function useGetUserLocation() {
    return () => {
        const promise = new Promise((resolve, reject) => {
            const geo = navigator.geolocation;
            const response: any = {
                latitude: null,
                longitude: null,
                error: null,
            };

            if (!geo) {
                response.error = {
                    code: 0,
                    message: 'Not supported',
                };

                resolve(response);
            } else {
                geo.getCurrentPosition(
                    position => {
                        response.latitude = position.coords.latitude;
                        response.longitude = position.coords.longitude;

                        resolve(response);
                    },
                    error => {
                        response.error = error;

                        resolve(response);
                    }
                );
            }
        });

        return promise;
    };
}

export default function useUserLocation() {
    const [position, setPosition]: [any, any] = useState({});
    const [error, setError] = useState(null);

    const onChange = ({coords}: any) => {
        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
        });
    };

    const onError = (error: any) => {
        setError(error.message);
    };

    useEffect(() => {
        const geo = navigator.geolocation;

        if (!geo) {
            setError('Geolocation is not supported');
            return;
        }

        const watcher = geo.watchPosition(onChange, onError);

        return () => geo.clearWatch(watcher);
    }, []);

    return {
        latitude: position.latitude,
        longitude: position.longitude,
        error: error
    };
}

export function useIsEmbedded() {
    const location = useLocation();

    const hasEmbeddedQuery = location.search.includes('embedded');

    if (hasEmbeddedQuery) {
        const embeddedValue = location.search.replace('?', '').split('&').find(part => part.includes('embedded')).split('=')[1];

        if (embeddedValue === 'true') {
            localStorage.setItem('embedded', 'true');
            return true;
        } else {
            localStorage.removeItem('embedded');
            return false;
        }
    } else {
        return localStorage.getItem('embedded') === 'true';
    }
}

export function useLatLngUrlQuery(): IGeoCoordinates {
    const location = useLocation();

    const coords: IGeoCoordinates = { lat: 42.69624528711392, lng: 23.32108335497111 };

    if (location.search.includes('lat') && location.search.includes('lng')) {
        const latVal = location.search.replace('?', '').split('&').find(part => part.includes('lat')).split('=')[1];
        const lngVal = location.search.replace('?', '').split('&').find(part => part.includes('lng')).split('=')[1];

        coords.lat = Number(latVal);
        coords.lng = Number(lngVal);
    }

    return coords;
}