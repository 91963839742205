export default function ConfirmationComponent(
    { header, body, rootClassName, headerClassName, bodyClassName }:
    { header: string, body: string, rootClassName?: string, headerClassName?: string, bodyClassName?: string }) {
    return (
        <div id="confirm" className={'text-center p-2 py-3' + (rootClassName ?? '')}>
            <div className="icon icon--order-success svg add_bottom_15">
                <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72}>
                <g fill="none" stroke="#8EC343" strokeWidth={2}>
                    <circle cx={36} cy={36} r={35} style={{strokeDasharray: '240px, 240px', strokeDashoffset: '480px'}} />
                    <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style={{strokeDasharray: '50px, 50px', strokeDashoffset: '0px'}} />
                </g>
                </svg>
            </div>
            <h3 className={headerClassName}>{header}</h3>
            <p className={bodyClassName}>{body}</p>
        </div>
    );
}