import { t } from "i18next";
import { Link } from "react-router-dom";

export default function DashboardSidebarComponent({ active }: { active: string }) {
    return (
        <div className="navbar-expand-lg dashboard-sidebar" style={{ flexBasis: '15%', borderRight: '1px solid rgb(237, 237, 237)' }}>
            <div className="collapse navbar-collapse flex-column align-items-start py-4" id="navbarResponsive">
                <ul className="navbar-nav flex-column w-100">
                    <li className={`nav-item ${active === 'dashboard' ? 'active' : ''}`}>
                        <Link className="nav-link pl-4" to="/home">
                            {/*<img src={img('icons/pay.svg')} className="svg-pay mr-2" alt={''} />*/}
                            <span className="nav-link-text">{t('Аз Foody-то')}</span>
                        </Link>
                    </li>
                    <li className={`nav-item ${active === 'past-orders' ? 'active' : ''}`}>
                        <Link className="nav-link pl-4" to="/past-orders">
                            {/*<img src={img('icons/pay.svg')} className="svg-pay mr-2" alt={''} />*/}
                            <span className="nav-link-text">{t('Моите поръчки')}</span>
                        </Link>
                    </li>
                    <li className={`nav-item ${active === 'profile' ? 'active' : ''}`}>
                        <Link className="nav-link pl-4" to="/profile">
                            {/*<img src={img('icons/user-male.svg')} className="svg-user mr-2" alt={''} />*/}
                            <span className="nav-link-text">{t('Моят профил')}</span>
                        </Link>
                    </li>
                    <li className={`nav-item ${active === 'notifications' ? 'active' : ''}`}>
                        <Link className="nav-link pl-4" to="/notifications">
                            {/*<img src={img('icons/alarm.svg')} className="svg-user mr-2" alt={''} />*/}
                            <span className="nav-link-text">{t('Известия')}</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}