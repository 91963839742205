import { apiGet, apiPost } from "@app/utils/http";

export interface ILoginCredentials {
    username: string;
    password: string;
}

export interface ILoginResponse {
    token?: string;
    message?: string;
    user_email?: string;
    user: {
        id: number;
        firstName: string;
        lastName: string;
        phone: string;
    }
}

export interface ISignUpCredentials {
    firstName: string,
    lastName: string,
    phone: string;
    username: string,
    password: string,
    country: string,
    city: string,
}

export interface ISignUpResponse {
    response: string,
    user: {
        id: number;
    },
}

export interface ISignUpError {
    response: {
        field: string,
        reason: string,
    }
}

export interface IGetterResponse {
    response: string,
}

export default class UserService {
    static login(credentials: ILoginCredentials): Promise<ILoginResponse> {
        return apiPost('@auth/token', { ...credentials, custom_auth: false })
                .then(response => (response as any) as ILoginResponse);
    }

    static logout(): Promise<any> {
        return apiGet('@api/users/logout')
                .then(response => response as any);
    }

    static signup(credentials: ISignUpCredentials): Promise<ISignUpResponse> {
        return apiPost('@api/users/signup', credentials)
            .then(response => (response as any) as ISignUpResponse);
    }

    static getUserById(credentials: {id: number}): Promise<any> {
        return apiPost(`@api/users/${credentials.id}`, {})
            .then(response => (response as any) as ISignUpResponse);
    }

    static getUserByEmail(credentials: {email: string}): Promise<any> {
        return apiPost(`@api/users/${credentials.email}`, {})
            .then(response => (response as any));
    }

    static changePersonal(data: any): Promise<any> {
        return apiPost('@api/profile/users/changePersonal', data)
            .then(response => (response as any));
    }

    static changePassword(data: any): Promise<any> {
        return apiPost('@api/profile/users/changePassword', data)
            .then(response => (response as any));
    }

    static forcePasswordChange(data: any): Promise<any> {
        return apiPost('@api/profile/users/forcePasswordChange', data)
            .then(response => (response as any));
    }

    static retrievePassword(data: any): Promise<any> {
        return apiPost('@api/users/retrievePassword', data, true)
            .then(response => (response as any));
    }

    static resetPassword(data: any): Promise<any> {
        return apiPost('@api/users/resetPassword', data, true)
            .then(response => (response as any));
    }
}