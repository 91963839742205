import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import LocationSearchComponent from '../ui/location-search';

import { hideGlobalModal } from '@app/utils/modals';

export default function AddressModalComponent() {
    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                open={true}
                fullWidth={true}
                className="mui-modal-login modal-pick-address"
                onClose={() => hideGlobalModal('address')}>
                <DialogContent>
                    <div className="modal_header bg_gray text-center w-100 py-3 m-0">
                        <h3 className="m-0">{t('Потърси по адрес')}</h3>
                    </div>
                    <div id="sign-in-dialog" className="zoom-anim-dialong">
                        <div className="description">{t('PICK_ADDRESS_DESCRIPTION')}</div>

                        <LocationSearchComponent onSubmit={() => hideGlobalModal('address')} />

                        <button
                            title="Close (Esc)"
                            type="button"
                            className="mfp-close"
                            onClick={() => hideGlobalModal('address')}></button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}