import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProductSearchState {
    text?: string
}

const initialState: ProductSearchState = {
    text: null,
};

const slice = createSlice({
    name: 'textSearch',
    initialState,
    reducers: {
        setTextSearchState: (state: ProductSearchState, action: PayloadAction<string>): void => {
            state.text = action.payload;
        },

    },
});

const textSearchReducer = slice.reducer;

export const { setTextSearchState } = slice.actions;

export default textSearchReducer;