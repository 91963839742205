import { apiGet, apiPut } from "@app/utils/http";

export default class MailingService {
    static subscribe(email: string, type: string, source: string): Promise<Response> {
        return apiPut(`@api/mailing`, { email, type, source });
    }

    static hasSub(email: string): Promise<Response> {
        return apiGet(`@api/hasSub/${email}`, true);
    }
}