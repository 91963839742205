import { setStoredCartCouponNewsletter, setStoredCartMail } from "@app/store/cart.store";
import { setCheckoutMail } from "@app/store/checkout.store";
import { img } from "@app/style/utils";
import { hideGlobalModal } from "@app/utils/modals";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SubscribeComponent from "../subscribe";
import ConfirmationComponent from "../ui/confirmation";

export default function NewsletterModalComponent() {
    const [success, setSuccess] = useState(false);
    const [successTimeout, setSuccessTimeout] = useState(3);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (success) {
            if (successTimeout === 0) {
                hideGlobalModal('newsletter');
            } else {
                setTimeout(() => {
                    setSuccessTimeout(prev => prev - 1);
                }, 1000);
            }
        }
    }, [success, successTimeout]);

    function onSubscribe(res: any) {
        setSuccess(true);
        dispatch(setStoredCartCouponNewsletter(res.couponNewsletter));
        dispatch(setStoredCartMail(res.mail));
        dispatch(setCheckoutMail(res.mail));
        localStorage.setItem('newsletter_popup', `${Date.now()}`);
    }

    return (
        <div className="popup_wrapper" style={{ opacity: 1 }}>
            <div className="popup_content newsletter_c">
                <span className="popup_close">
                    <i className="icon_close" onClick={() => {
                        hideGlobalModal('newsletter');
                        localStorage.setItem('newsletter_popup', `${Date.now()}`);
                    }}/>
                </span>
                <div className="row g-0">
                    <div className="col-md-5 d-none d-md-flex align-items-center justify-content-center" style={{position: 'relative'}}>
                        <figure>
                            <img src={img('logo/foody-dark-underlined.png')} className="" alt="" width={165} />
                        </figure>
                    </div>
                    <div className="col-md-7">
                        <div className="content">
                            <div className="wrapper">
                                {success
                                    ? <ConfirmationComponent
                                        header={t('NEWSLETTER_SIGN_UP_SUCCESS')}
                                        body={`${t('CLOSING_AFTER')} ${successTimeout}...`}
                                        bodyClassName="success-countdown text-center" />
                                    : <>
                                        <i className="icon_mail_alt display-4"></i>
                                        <h3>{t('NEWSLETTER_SIGN_UP')}</h3>
                                        <p>{t('NEWSLETTER_POPUP_DISCOUNT')}</p>
                                        <SubscribeComponent
                                            target="newsletter"
                                            source="popup"
                                            button={t('Запиши се')}
                                            message={t('SIGN_UP_SUCCESS_FEEDBACK')}
                                            className={'contrast'}
                                            border={true}
                                            onSubscribeCallback={onSubscribe}
                                            captchaClassName={'captcha-newsletter'} />
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}