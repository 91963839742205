import { useJsApiLoader } from "@react-google-maps/api";
import { serverImage } from '@app/style/utils';

import ProductEntity from '@app/entities/product.entity';

const gLibraries = ['places'];

export function useGoogle(): [ boolean, Error ] {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.GMAPS_KEY,
        libraries: gLibraries as any,
        //language: window.localStorage.getItem('i18nextLng')
        language: 'en'
    });

    return [ isLoaded, loadError ];
}

export function getGoogleComponent(place: google.maps.places.PlaceResult, component: string): string|null {
    switch (component) {
        case 'city':
            return place?.address_components?.find((component) => component.types.includes('locality'))?.short_name;
        case 'address':
            return place?.name;
    }

    return null;
}

export function schemaProduct(product: ProductEntity) {
    return {
        '@context': 'https://schema.org',
        '@type': 'Product',
        'name': product.altName || product.name,
        'description' : product.getDescription(),
        'identifier': `${product.wcId}`,
        'image': serverImage('product/' + product.wcId),
        'url': `${process.env.BASE_PATH}offers/${product.slug}`,
        'offers': {
            '@type': 'Offer',
            'url': `${process.env.BASE_PATH}offers/${product.slug}`,
            'itemCondition': 'https://schema.org/NewCondition',
            'availability': product.quantity > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
            'price': `${product.priceSale}`,
            'priceCurrency': 'BGN',
            'category': product.categories.map(cat => cat.name),
            'seller': {
                '@type': 'Organization',
                'brand': {
                    '@type': 'Brand',
                    'name': product.vendor.name
                },
                'address': {
                    '@type': 'PostalAddress',
                    'addressCountry': product.vendor.country,
                    'addressRegion': product.vendor.city,
                    'postalCode': product.vendor.zip,
                },
                'areaServed': product.vendor.city,
                'location': {
                    '@type': 'Place',
                    'address': product.vendor.address,
                    'latitude': `${product.vendor.lat}`,
                    'longitude': `${product.vendor.lng}`,
                },
                /*
                'logo': serverImage('vendor/' + productVendor.wcId),
                'sameAs': Object.values(productVendor.contacts),
                'keywords': [productVendor.name, productVendor.slug],
                */
            },
        },
        /*
        'review': comments.data.map((review: CommentEntity) => {
            return {
                '@type': 'Review',
                'reviewRating': {
                    '@type': 'Rating',
                    'ratingValue': `${review.rating}`,
                    'bestRating': '5'
                }
            };
        }),
        'aggregateRating': {
            '@type': 'AggregateRating',
            'ratingValue': `${comments.totalAverage}`,
            'reviewCount': `${comments.data.length}`
        }
        */
    };
}

export function generateStructuredFAQ(content: any) {
    document.querySelector('#schemaorg')?.remove();

    const element = document.createElement('script');

    element.setAttribute('type', 'application/ld+json');
    element.setAttribute('id', 'schemaorg');

    element.textContent = JSON.stringify(content);

    document.head.appendChild(element);

    return element;
}