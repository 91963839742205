import { Slice, createSlice } from '@reduxjs/toolkit';
import { ICartShippingOption, ICartVendor } from "@app/services/cart.service";

export interface CheckoutState {
    name: string;
    mail: string;
    phone: string;
    city: string;
    zip: string;
    address: string;
    addressNote: string;
    password: string;
    shippingOption?: ICartShippingOption;
    shippingVendorId?: number;
    shippingCost?: number;
    currentOrderId?: number | null;
    stripeResponse?: any;
}

const initialState: CheckoutState = {
    name: '',
    mail: '',
    phone: '',
    city: null,
    zip: '',
    address: '',
    addressNote: '',
    password: '',
    shippingVendorId: null,
    shippingCost: 0,
    currentOrderId: null,
    stripeResponse: null,
};

const slice: Slice<any, any, any> = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setCheckout: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: CheckoutState };

            Object.keys(payload).forEach(key => (state as any)[key] = (payload as any)[key]);
        },
        setCheckoutName: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.name = payload;
        },
        setCheckoutMail: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.mail = payload;
        },
        setCheckoutPhone: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: string };

            state.phone = payload;
        },
        setCheckoutShipping: (state: CheckoutState, action?: any, b?: any): any => {
            const { option, vendors } = action.payload as { option: ICartShippingOption, vendors: ICartVendor[] };

            state.shippingOption = option;

            switch (option.name.toLowerCase()) {
                case 'glovo':
                    let vendor = vendors.reduce((current, vendor) => current || (vendor.wcId === state.shippingVendorId ? vendor : null), null);

                    if (!vendor) {
                        vendor = vendors.reduce((current, vendor) => current || (vendor.shippable?.glovo ? vendor : null), null);
                    }

                    state.shippingCost = vendor.shippingCost['glovo'];
                    break;
                default:
                    state.shippingCost = option.cost;
            }
        },
        setCheckoutShippingOption: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: ICartShippingOption };
            state.shippingOption = payload;
        },
        setShippingVendorId: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: number };
            state.shippingVendorId = payload;
        },
        setShippingCost: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: number };
            state.shippingCost = payload;
        },
        setCurrentOrderId: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: number };
            state.currentOrderId = payload;
        },
        setStripeResponse: (state: CheckoutState, action?: any): any => {
            const { payload } = action as { payload: number };
            state.currentOrderId = payload;
        },
        emptyShipping: (state: CheckoutState, action?: any): any => {
            state.shippingCost = null;
            state.shippingVendorId = null;
        },
    },
});

const reducer = slice.reducer;

export const {
    setCheckout,
    setCheckoutName,
    setCheckoutMail,
    setCheckoutPhone,
    setCheckoutShipping,
    setCheckoutShippingOption,
    setShippingVendorId,
    setShippingCost,
    setCurrentOrderId,
    setStripeResponse,
    emptyShipping
 } = slice.actions as any;

export default reducer;