import { UserState } from './user.store';
import { CheckoutState } from './checkout.store';
import { SearchState } from './search.store';
import { useSelector } from 'react-redux';

export function useCart() {
    return useSelector((state: any) => state.cart || {});
}

export function useCartSyncronized() {
    return useSelector((state: any) => state.cart.syncronized);
}

export function useCartProcessing(): boolean {
    return useSelector((state: any) => state.cart.processing && state.cart.processing.length > 0);
}

export function useCartCoupon(): string {
    return useSelector((state: any) => state.cart.coupon);
}

export function useCartCouponNewsletter(): string {
    return useSelector((state: any) => state.cart.couponNewsletter);
}

export function useCartQuantity(): number {
    return useSelector((state: any) => state.cart.products
            ? Object.values(state.cart.products)
                .reduce((acc: number, cur: number) => acc + cur, 0)
            : 0) as number;
}

export function useCartProductQuantity(id: number) {
    return useSelector((state: any) => state.cart.products[id] ? state.cart.products[id] : null);
}

export function useCartProductProcessing(productId: number): boolean {
    return useSelector((state: any) => {
        return state.cart.processing && state.cart.processing.filter((id: number) => id === productId).length > 0;
    });
}

export function useSearchPlace(): google.maps.places.PlaceResult {
    return useSelector((state: any) => state.search.place);
}

export function useSearchAddress(): string {
    const searchPlace = useSearchPlace();

    const components = searchPlace && searchPlace?.address_components ? searchPlace?.address_components : [];

    const street = [
        components.reduce((n, c) => c.types.includes('route') ? (c.short_name as string) : n, null),
        components.reduce((n, c) => c.types.includes('street_number') ? (c.long_name as string) : n, null),
    ].filter((c) => c).join(' ');

    const address = [
        components.reduce((n, c) => c.types.includes('locality') ? c.long_name : n, null),
        street,
    ].filter((c) => c).join(', ');

    return address;
}

export function useSearchStore(): SearchState {
    return useSelector((state: any) => state.search);
}

export function useStoredCheckoutData(): CheckoutState {
    return useSelector((state: any) => state.checkout);
}

export function useStoredUser(): UserState {
    return useSelector((state: any) => state.user);
}

export function useSearchedText(): string {
    const text =  useSelector((state: any) => state.textSearch.text);
    return text;
}