import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from '@app/routes';
import ScrollToTop from '@app/components/utils/scroll-to-top';

import CartService from './services/cart.service';
import { setStoredCartProducts, setStoredCartSyncronized } from './store/cart.store';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import '@app/scss/theme/theme.scss';

function App() {
    const dispatch = useDispatch();
    const gtmParams = { id: process.env.GTAG_ID };

    useEffect(() => {
        CartService.get()
            .then(response => {
                const products: { [key: number]: number } = {};

                response.items.forEach(item => {
                    products[item.wcId] = products[item.wcId]
                            ? products[item.wcId] + item.quantity
                            : item.quantity;
                });

                dispatch(setStoredCartProducts(products));
                dispatch(setStoredCartSyncronized(true));
            });
    }, []);

    return (
        <GTMProvider state={gtmParams}>
            <Router>
                <ScrollToTop>
                    <div id={`page`}>
                        <Routes />
                    </div>
                </ScrollToTop>
            </Router>
        </GTMProvider>
    );
}

export default App;