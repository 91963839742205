import { useState, useEffect } from 'react';
import { useLocation, Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TextSearchService, { ITextSearchResponse, ITextSearchEntity, PRODUCT_TYPE, VENDOR_TYPE, BLOG_TYPE } from '@app/services/search.text.service';
import { serverImage } from '@app/style/utils';
import Loader from '@app/components/ui/loader';
import {  useSearchedText } from '@app/store/selectors';
import SearchAllBar from '@app/components/ui/search.all';

import '@app/scss/theme/pages/_search-all.page.scss';
import '@app/scss/theme/pages/_blog.page.scss';

function ListItem({ entity }: { entity: ITextSearchEntity }) {
    let image = null;
    let link = null;

    if (entity.type === PRODUCT_TYPE) {
        image = serverImage(`product/${entity.wcId}`);
    } else if (entity.type === VENDOR_TYPE) {
        image = serverImage(`vendor/${entity.wcId}`);
    } else {
        image = 'https://foody.to/wp-content/uploads/2022/10/rsz_1img_0267.jpg' || 'https://foody.to/images/logo-square.png';
    }

    if (entity.type === PRODUCT_TYPE) {
        link = `/product/${entity.slug}`;
    } else if (entity.type === VENDOR_TYPE) {
        link = `/vendor/${entity.slug}`;
    } else {
        link = `/blog/${entity.slug}`;
    }

    return (
        <div className={`list-item`}>
            <div className={`img-container ${entity.type === PRODUCT_TYPE  && entity.settings.quantity < 1 ? 'gray' : ''}`}>
                <img src={image} alt={''} />
            </div>
            <div className="content">
                <h3><Link to={link} dangerouslySetInnerHTML={{ __html: entity.name }} /></h3>
                <div className="description" dangerouslySetInnerHTML={{ __html: entity.description }} />
                {entity.type === PRODUCT_TYPE  && entity.settings.quantity < 1 ? <span className="out-of-stock">Извън наличност</span> : null}
            </div>
        </div>
    );
}

function SearchAllResultsList({ list }: { list: ITextSearchEntity[] }) {
    return (
        <div className="list-view">
            {list.map(item => {
                if (item.type === BLOG_TYPE) {
                    if (item.description.indexOf("<span class='highlight'>") === -1) {
                        item.description = item.description.split(' ').slice(0, 30).join(' ');
                    }

                    if (!item.description.endsWith('...')) {
                        item.description += '...';
                    }
                }

                return <ListItem entity={item} key={item.wcId} />;
            })}
        </div>
    );
}

function SearchAllResults() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const pathArgs = location.pathname.split('/').filter(type => [PRODUCT_TYPE, VENDOR_TYPE, BLOG_TYPE].includes(type));
    const entityType = pathArgs[0];

    const { t } = useTranslation();

    const text = useSearchedText();

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<ITextSearchResponse>({[PRODUCT_TYPE]: [], [VENDOR_TYPE]: [], [BLOG_TYPE]: []});

    useEffect(() => {
        if (searchParams.get('q')) {
            setLoading(true);

            TextSearchService
                .get(searchParams.get('q'))
                .then(res => {
                    res[PRODUCT_TYPE] = res[PRODUCT_TYPE].sort((a, b) => {
                        return  a.settings.status === 'publish' ?
                                -1 :
                                (b.settings.status === 'publish' ?
                                1 : 0);
                    });

                    setResults(res);
                })
                .finally(() => setLoading(false));
        } else if (text !== null) {
            setLoading(true);

            TextSearchService
                .get(text)
                .then(res => {
                    res[PRODUCT_TYPE] = res[PRODUCT_TYPE].sort((a, b) => {
                        return  a.settings.status === 'publish' ?
                                -1 :
                                (b.settings.status === 'publish' ?
                                1 : 0);
                    });

                    setResults(res);
                })
                .finally(() => setLoading(false));
        }
    }, [text]);

    return (
        <>
            {[PRODUCT_TYPE, VENDOR_TYPE, BLOG_TYPE].map((type: string, index: number) => {
                return (!entityType || entityType === type)
                    ? <div key={index} className="results-group">
                        <h2 className="bg_gray pattern-foody">{t(type)}</h2>
                        <Loader loading={loading}>
                            {results[type as keyof typeof results].length > 0
                                ? <>
                                    <SearchAllResultsList
                                        list={entityType
                                            ? results[type as keyof typeof results]
                                            : results[type as keyof typeof results].slice(0, 3)}
                                    />
                                    <div className="actions">
                                        {!entityType && results[type as keyof typeof results].length > 3
                                            ? <Link to={`/search/${type}?q=${text}`} className="more">{t('Виж всички')}</Link>
                                            : null}
                                    </div>
                                </>
                                : <h2 className="no-results-header">{t('No results found')}</h2>}
                        </Loader>
                    </div>
                    : null;
            })}
        </>
    );
}

export default function SearchAllPage() {
    return (
        <div className="page-content search-all">
            <main>
                <div className="section-search bg_gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 offset-3">
                                <SearchAllBar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-results">
                    <div className="container">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <SearchAllResults />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}