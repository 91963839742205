import React from "react";
import { css } from "@emotion/css";

// @ts-ignore
import { Eclipse } from "react-loading-io";

const style = css({
    '.loader-container':{
        margin: '34px auto 40px',
        textAlign: 'center',

        '&.full-page': {
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            background: 'rgba(255, 255, 255, 0.82)',
            top: 0,
            left: 0,
            paddingTop: '35vh',
            marginTop: 0,
        },

        '.loader-content': {
            marginTop: 15,
            fontWeight: 500,
            fontSize: 15,
        },
    }
});

export default function Loader({ loading, children, className, fullPage, content }:
    { loading: boolean, children?: React.ReactNode, className?: string, fullPage?: boolean, content?: React.ReactNode }
) {
    return (
        <div className={`${style} ${className}`}>
            {
                loading
                    ? <div className={`loader-container ${fullPage ? 'full-page' : ''}`}>
                        <Eclipse size={64} />
                        {content ?
                            <div className="loader-content">{content}</div> :
                            null}
                    </div>
                    : children
            }
        </div>
    );
}