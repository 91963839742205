import { ReactNode, useEffect, useRef, useState } from "react";
import { useForm } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import MailingService from "@app/services/mailing.service";
import CaptchaService from "@app/services/captcha.service";
import { emailRegex } from "@app/utils/validations";
import { useTranslation } from "react-i18next";
import Loader from "./ui/loader";
import { resetCaptcha, useRecaptcha } from "@app/utils/recaptcha";

export default function SubscribeComponent({
    target = 'newsletter',
    source = 'footer',
    button = <i className="arrow_carrot-right"></i>,
    className,
    border = false,
    onSubscribeCallback,
    captchaClassName
}: { target?: string, source?: string, button?: ReactNode, message?: string, className?: string, border?: boolean, onSubscribeCallback?: (res: any) => void, captchaClassName?: string }) {
    const [mail, setMail] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isCaptchaInit, initCaptcha]  = useRecaptcha();

    const captchaRef = useRef(null);

    const { t } = useTranslation();

    const { register, handleSubmit, clearErrors, formState: { errors }, setError } = useForm({
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        criteriaMode: 'firstError',
    });

    useEffect(() => {
        if (isCaptchaInit) {
            prepareCaptcha();
        }
    }, [isCaptchaInit]);

    function prepareCaptcha() {
        setCaptcha('');

        resetCaptcha({
            ref: captchaRef,
            onSuccess: (response: string) => {
                clearErrors();
                setCaptcha(response);
            },
        });
    }

    function onSubmit() {
        if (!showCaptcha) {
            setShowCaptcha(true);
            return;
        }

        if (!captcha) {
            setError('captcha', { message: t('REQUIRED_CAPTCHA'), type: 'required' });
            return;
        }

        clearErrors();
        setLoading(true);

        CaptchaService
            .verify(captcha)
            .then(res => {
                return MailingService
                    .subscribe(mail, target, source)
                    .then((res: any) => {
                        setSubscribed(true);

                        if (onSubscribeCallback) {
                            onSubscribeCallback(res);
                        }
                    })
                    .catch((e: any) => {
                        setSubscribed(false);

                        if (e.status === 403) {
                            setError('email_newsletter', { message: `${t('Already signed up newsletter')}`, type: 'validate' });
                            prepareCaptcha();
                        }
                    });
            })
            .catch(err => {
                setSubscribed(false);
                setError('captcha', { message: t('ERROR_CAPTCHA'), type: 'required' });
                prepareCaptcha();
            })
            .finally(() => setLoading(false));
    }

    return (
        <div id="newsletter" className={className || ''}>
            <div id="message-newsletter"></div>
                <Loader loading={loading}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {subscribed &&
                            <div className="success-message mt-2">{t('NEWSLETTER_SIGN_UP_SUCCESS')!}</div>}

                        {!subscribed &&
                            <div className={`form-group ${errors['email_newsletter'] ? 'error' : ''}`}>
                                <input type="email"
                                    name="email_newsletter"
                                    id="email_newsletter"
                                    onFocus={initCaptcha}
                                    className={`form-control ${border ? 'border' : ''}`}
                                    placeholder={t('YOUR_EMAIL_PLACEHOLDER')}
                                    disabled={loading}
                                    value={mail}
                                    {...register('email_newsletter', {
                                        onChange: (e: any) => setMail(e.target.value),
                                        required: t('Полето е задължително'),
                                        pattern: {
                                            value: emailRegex,
                                            message: t('INVALID_EMAIL_MESSAGE'),
                                        }
                                    })} />
                                <button
                                    disabled={loading}
                                    type="submit"
                                    id="submit-newsletter">{button}</button>
                                <div ref={captchaRef} className={`g-recaptcha mt-3 ${captchaClassName} ${showCaptcha ? '' : 'd-none'}`}></div>

                                <div className="error-message mt-2">
                                    <ErrorMessage errors={errors} name="email_newsletter" />
                                    <ErrorMessage errors={errors} name="captcha" />
                                </div>
                            </div>
                        }
                    </form>
                </Loader>
        </div>
    );
}
