import { img } from "@app/style/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SubscribeComponent from "./subscribe";

import FollowLinksComponent from "./ui/follow.links";
//import ServerImage from "./ui/server.image";

export default function Footer() {
    const [opened, setOpened] = useState([]);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('i18nextLng');

    function isOpened(id: string) {
        return opened.includes(id);
    }

    function toggle(id: string) {
        if (opened.includes(id)) {
            setOpened(opened.filter(val => val !== id));
        } else {
            setOpened(opened.concat([id]));
        }
    }

    return (
        <footer>
            <div className="container">
                <div className="row with-cols mb-4">
                    {
                        storedLanguage.startsWith('bg')
                            ? <div className="col-lg-3 col-md-6">
                                <h3 onClick={() => toggle('section-ic')}>Innovation Capital</h3>
                                <div className={`dont-collapse-sm fund-logos links ${isOpened('section-ic') ? '': 'collapse'}`}
                                    style={{color: '#fff', fontSize: 12}}>
                                    {t('IC_FUNDING_EXPLANATION')}
                                    <br />
                                </div>
                            </div>
                            : null
                    }
                    <div className="col-lg-3 col-md-6">
                        <h3 onClick={() => toggle('section-1')}>{t('Бързи връзки')}</h3>
                        <div className={`dont-collapse-sm links ${isOpened('section-1') ? '': 'collapse'}`}>
                            <ul>
                                <li><Link to="/">{t('Начало')}</Link></li>
                                <li><Link to="/offers">{t('Хапни')}</Link></li>
                                <li><Link to="/vendors">{t('Места')}</Link></li>
                                <li><Link to="/partners">{t('Стани партньор')}</Link></li>
                                <li><Link to="/faq">{t('FAQ')}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 onClick={() => toggle('section-3')}>{t('Контакти')}</h3>
                        <div className={`dont-collapse-sm contacts ${isOpened('section-3') ? '': 'collapse'}`}>
                            <ul>
                                {/*<li><i className="icon_house_alt"></i>97845 Baker st. 567<br />Los Angeles - US</li>
                                <li><i className="icon_mobile"></i>+94 423-23-221</li>*/}
                                <li><i className="icon_mail_alt"></i>info@foody.to</li>
                            </ul>
                        </div>
                    </div>
                    <div className={storedLanguage.startsWith('en') ? "offset-lg-3 col-lg-3 col-md-6" : "col-lg-3 col-md-6"} >
                        <h3 onClick={() => toggle('section-4')}>{t('NEWSLETTER_SIGN_UP')}</h3>
                        <div className={`dont-collapse-sm ${isOpened('section-4') ? '': 'collapse'}`}>
                            <SubscribeComponent />
                            <div className="follow_us">
                                <h5>{t('Последвай ни')}</h5>
                                <FollowLinksComponent />
                            </div>
                        </div>
                    </div>
                </div>

                {/*
                <div className="funds">
                    <ServerImage src={'theme/partners/eu_bg.jpg'} height={50} />
                    <ServerImage src={'theme/partners/fof_bg.png'} height={50} />
                    <ServerImage src={'theme/partners/opic_bg.jpg'} height={50} />
                    <ServerImage src={'theme/partners/ic.png'} height={50} />
                </div>

                <hr />
                */}

                <div className="row add_bottom_25">
                    <div className="col-lg-6">
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings btn_1"><img src={img('icons/settings-alt.svg')} className="svg-settings-alt" alt={''} /></button>
                        {/*
                        <ul className="footer-selector clearfix">
                            <li>
                                <div className="styled-select lang-selector">
                                    <select>
                                        <option value="English" selected>English</option>
                                        <option value="French">French</option>
                                        <option value="Spanish">Spanish</option>
                                        <option value="Russian">Russian</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div className="styled-select currency-selector">
                                    <select>
                                        <option value="US Dollars" selected>US Dollars</option>
                                        <option value="Euro">Euro</option>
                                    </select>
                                </div>
                            </li>
                            <li><img src={img('theme/cards_all.svg')} alt="" width="198" height="30" className="lazy" /></li>
                        </ul>
                        */}
                    </div>
                    <div className="col-lg-6">
                        <ul className="additional_links">
                            <li><Link to="/terms">{t('Общи условия')}</Link></li>
                            <li><Link to="/privacy">{t('Политика по поверителност')}</Link></li>
                            <li><span>© Foody</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}